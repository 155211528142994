import { request, ApiValidationError, ApiServerError } from "@/services/api";
import { mapResponse2 as mapResponse } from "@/utils/map-checkable-response";
export async function login(data) {
    const result = await request
        .checkablePost("/auth/login", data);
    return mapResponse(result, {
        500: ApiServerError,
        400: ApiValidationError,
    });
}
