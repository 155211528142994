import { User } from "@/models/User";
import { ApiValidationError } from "@/services/api";
import { TransformValidationError } from "@/services/transform";
import { fail, success } from "@/utils/either";
import { login } from "./api";
export async function loginAndTransformUser(data) {
    var _a, _b;
    const result = await login(data);
    if (result.isOk()) {
        return success({
            accessToken: result.value.auth_token,
            user: new User(result.value.user),
        });
    }
    else {
        if (result.value instanceof ApiValidationError) {
            const errors = await result.value.getObjectErrors();
            const transformedErrors = new TransformValidationError({
                email: (_a = errors.email) !== null && _a !== void 0 ? _a : [],
                password: (_b = errors.password) !== null && _b !== void 0 ? _b : [],
            });
            return fail(transformedErrors);
        }
        else {
            return fail(result.value);
        }
    }
}
