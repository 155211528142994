import { computed, ref } from "@vue/composition-api";
import { copy } from "copy-anything";
export function useFields(fieldsData) {
    const fields = ref(copy(fieldsData));
    const fieldObjects = computed(() => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const initialA = {};
        return fields.value.reduce((a, f) => ({
            ...a,
            [f.name]: f,
        }), initialA);
    });
    const fieldValues = computed(() => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const initialA = {};
        return fields.value.reduce((a, f) => ({
            ...a,
            [f.name]: f.value,
        }), initialA);
    });
    const setField = (name, value) => {
        const field = fields.value.find(f => f.name === name);
        if (field === undefined)
            throw new Error(`FIELD "${name}" NOT FOUND`);
        field.value = value;
    };
    const clearFields = (names) => {
        if (names !== undefined && names.length > 0) {
            names.forEach(name => {
                const initialField = fieldsData.find(f => f.name === name);
                if (initialField === undefined)
                    throw new Error(`FIELD "${name}" NOT FOUND`);
                setField(name, initialField.value);
            });
        }
        else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            fields.value = copy(fieldsData);
        }
    };
    return {
        fields: computed(() => fields.value),
        fieldObjects,
        fieldValues,
        setField,
        clearFields,
    };
}
