import { defineComponent, ref } from "@vue/composition-api";
import ValidationErrorList from "@/components/common/ValidationErrorList.vue";
import { login as serviceLogin, ValidationError } from "@/services/user/login";
import { useValidationErrors } from "@/composable/validation-errors";
import { useFields } from "@/composable/fields";
import { setUser } from "@/store/user.ts";
import { setAccessToken } from "@/store/access-token.ts";
export default defineComponent({
    name: "LoginView",
    components: {
        ValidationErrorList,
    },
    setup(props, ctx) {
        const isAuthError = ref(false);
        const isAuthException = ref(false);
        const { fields, fieldValues, setField } = useFields([
            { title: "Поштова скринька", name: "email", secure: false, value: "" },
            { title: "Пароль", name: "password", secure: true, value: "" },
        ]);
        const { errors, setErrors, clearErrors } = useValidationErrors();
        const login = async () => {
            isAuthError.value = false;
            isAuthException.value = false;
            const result = await serviceLogin.run(fieldValues.value);
            if (result.isOk()) {
                setUser(result.value.user);
                setAccessToken(result.value.accessToken);
                await ctx.root.$router.push({ name: "map" });
            }
            else {
                if (result.value instanceof ValidationError) {
                    const validationErrors = result.value.getErrors();
                    setErrors(validationErrors);
                    if (validationErrors.email.length === 0 && validationErrors.password.length === 0) {
                        isAuthError.value = true;
                    }
                }
                else {
                    isAuthException.value = true;
                    throw result.value;
                }
            }
        };
        return {
            fields,
            setField,
            login,
            errors,
            clearErrors,
            isAuthError,
            isAuthException,
        };
    },
});
